<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item >主题数据管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always" class="list-container">
      <div class="title">
        <span>数据来源</span>
        <div class="select">
          <el-select v-model="value" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="header">
        <div style="fontWeight:bold">过滤数据列表</div>
        <div class="header__btn" @click="addStock">+ 新增过滤数据</div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'bold',
            color: '#333',
          }" :cell-style="{ 'text-align': 'center', color: '#333' }" style="width: 100%">
          <el-table-column type="index" label="序号" width="100" />
          <el-table-column prop="dcThematic" label="主题名称" />
          <el-table-column prop="createTime" label="添加时间" />
          <el-table-column prop="createMan" label="添加人" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="deleteOperate(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog title="新增过滤数据" v-model="dialogVisible" :width="520">
      <el-form ref="form" label-width="80px" class="stock-form">
        <el-form-item label="主题名称">
          <div class="stockInfo" style="width: 450px">
            <el-input v-model.trim="stockInfo" @input="search" @keyup.enter="search()" maxlength="20" style="width: 280px"></el-input>
            <div class="empty" :class="{active:searchData.length>5}" v-if="searchData.length>0">
              <div v-for="(item,index) in searchData" :key="index">
                <div @click="sureAdd(item)">{{item}}</div>
              </div>
            </div>
          </div>
          <div class="stockState">{{ stockState }}</div>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="confirmNode" style="background-color:#38a28a">提 交</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      policyTitle: "",
      publishDate: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      dialogVisible: false,
      stockInfo: "",
      options: [{ value: '1', label: '东方财富' }],
      value: "1",
      stockState: "",
      stockForm: {
        stockName: "",
        stockCode: "",
      },
      allThematic: [],
      searchData: [],
      thematic: ''
    });
    watch(
      () => state.dialogVisible,
      (val) => {
        if (!val) {
          state.stockState = "";
          state.stockInfo = "";
          state.thematic = "";
          state.searchData = [];
        }
      }
    );
    watch(
      () => state.stockInfo,
      (val) => {
        if (!val) {
          state.searchData = [];
          state.thematic = "";
        }
      }
    );
    onMounted(() => {
      getList();
      //   getType();
    });
    const getList = () => {
      let data = {
        pageNo: state.currentPage,
        pageSize: state.pageSize,
      };
      proxy
        .$post("/cts/back/thematicExclude/page", data)
        .then((res) => {
          if (res.resultState === "200") {
            state.tableData = res.data.list;
            state.total = res.data.total;
          }
        })
        .catch((err) => { });
    };
    const getAllThematic = () => {
      proxy
        .$get("/cts/back/thematicExclude/allThematic")
        .then((res) => {
          if (res.resultState === "200") {
            state.allThematic = res.data
          }
        })
        .catch((err) => { });
    };
    const search = () => {
      if (!state.stockInfo) {
        return;
      } else {
        state.searchData = [];
        state.allThematic.forEach((res) => {
          const isInclude = res.indexOf(state.stockInfo.toUpperCase());
          if (isInclude > -1) {
            state.searchData.push(res);
          }
        });
      }
    };
    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    };
    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    };
    const sureAdd = (val) => {
      state.thematic = val;
      state.stockInfo = val;
    };
    const getType = () => {
      proxy
        .$get("/cts/back/digitalFrame/dateList")
        .then((res) => {
          if (res.resultState === "200") {
            state.options = res.data;
            // state.value = res.data[0].value;
            getList();
          }
        })
        .catch((err) => { });
    };
    const checkStockInfo = () => {
      if (!state.stockInfo) {
        ElMessage({
          message: "请输入股票代码或名称",
          type: "warning",
        });
        return;
      }
      let data = {
        codeOrName: state.stockInfo,
      };
      proxy
        .$get(
          "/cts/back/digitalFrame/validate" + "?codeOrName=" + state.stockInfo
        )
        .then((res) => {
          if (res.resultState === "200") {
            state.stockState = "校验成功";
            state.stockInfo = res.data.name;
            state.stockForm.stockName = res.data.name;
            state.stockForm.stockCode = res.data.stockCode;
          } else {
            state.stockInfo = "";
          }
        })
        .catch((err) => { });
    };
    const addStock = () => {
      state.dialogVisible = true;
      getAllThematic();
    };
    const confirmNode = () => {
      if (!state.thematic) {
        ElMessage({
          message: "请选择主题名称",
          type: "warning",
        });
        return;
      }
      proxy
        .$post("/cts/back/thematicExclude/add", state.thematic)
        .then((res) => {
          if (res.resultState === "200") {
            ElMessage({
              message: `${res.msg}`,
              type: "success",
            });
            state.dialogVisible = !state.dialogVisible;
            state.stockInfo = "";
            state.thematic = "";
            state.stockState = "";
            state.currentPage = 1;
            state.total = 0;
            getList();
          }
        })
        .catch((err) => { });
    };
    const deleteOperate = (data) => {
      ElMessageBox.alert("确认删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          let params = `?id=${data.id}`
          proxy
            .$get("/cts/back/thematicExclude/delete" + params)
            .then((res) => {
              if (res.resultState === "200") {
                ElMessage({
                  message: `${res.msg}`,
                  type: "success",
                });
                getList();
              }
            })
            .catch((err) => { });
        })
        .catch(() => { });
      return;
    };
    return {
      ...toRefs(state),
      handleSizeChange,
      handleCurrentChange,
      sureAdd,
      deleteOperate,
      addStock,
      confirmNode,
      getType,
      search,
      getAllThematic,
      checkStockInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
}
.list-container {
  position: relative;
  margin-top: 20px;
  .title {
    font-weight: bold;
    .select {
      margin-left: 20px;
      display: inline-block;
    }
    margin-bottom: 20px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    &__btn {
      background: rgb(56, 162, 138);
      width: 110px;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .content {
    margin-top: 15px;
    .page {
      float: right;
      margin: 20px;
    }
  }
}
::v-deep .el-dialog {
  padding-bottom: 40px;
}
::v-deep .el-dialog__body {
  padding: 20px 30px;
  padding-bottom: 0;
  .stockInfo {
    .empty {
      width: calc(100% - 40px);
      height: 100%;
      overflow-y: auto;
      background-color: #fff;
      padding-top: 5px;
      cursor: pointer;
      div:hover {
        background-color: #ccc;
      }
      &.active {
        height: 150px;
      }
    }
    .empty::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    /*鼠标悬浮在该类指向的控件上时滑块的样式*/
    .empty:hover::-webkit-scrollbar-thumb {
      // background-color: rgba(0, 0, 0, 0.6);
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
      box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
    }
    /*正常时候的主干部分*/
    .empty::-webkit-scrollbar-track {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
      // background-color: #000;
      background-color: rgba(0, 0, 0, 0.01);
    }
  }
  .stockState {
    color: #38a28a;
    font-weight: bold;
  }
  .stock-form {
    width: 400px;
  }
}
::v-deep .el-dialog__footer {
  padding: 10px 40px;
  text-align: left;
}
</style>
